<template>
  <div class="ProjectMain">
    <caseProjectDetail caseProjectType='project'></caseProjectDetail>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import caseProjectDetail from '@/components/caseProjectDetail.vue';
export default defineComponent({
  name:"ProjectMain",
  components:{
    caseProjectDetail
  }
})
</script>

<style lang="scss" scoped>

</style>